import React from "react"
import styled from "styled-components"
import MerchantTable from "../components/merchantList"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default function ShopsThatAcceptBitcoin({ data }) {
  return (
    <>
      <SEO title="" />
      <Page></Page>
    </>
  )
}

export const query = graphql`
  query MerchantDetailQuery {
    allGoogleSheet1Sheet {
      edges {
        node {
          merchant
          region
          category
        }
      }
    }
  }
`

const Page = styled.div`
  padding-bottom: 3em;
  min-height: calc(100vh - 65px);
  color: #333;

  @media only screen and (max-width: 1169px) {
    padding: 130px 1rem 0 1rem;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;

    .top-text {
      text-align: center;
      margin-bottom: 2em;

      h1 {
        color: #2d3e52;
        font-weight: 400;
      }

      p {
        font-size: 20px;
        color: #838383;
        margin-top: 1rem;
        line-height: 1.633;
      }
    }
  }
`
